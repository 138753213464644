import { useLoyaltyContext } from '../context/LoyaltyContext'
import React from 'react'
import { SignupForm } from './SignupForm/SignupForm'
import { SignupMethod } from '../types/types'
import { Successful } from './Successful/Successful'
import { InactiveProgram } from '../InactiveProgram/InactiveProgram'
import { Loading } from '../Loading/Loading'
import { ErrorPage500 } from '@toasttab/buffet-pui-error-pages'
import { Layout } from '../Layout/Layout'

export const SignUp = () => {
  const { configLoading, config, configError, isSignUpSuccess, isSignUpError } =
    useLoyaltyContext()

  if (configError || isSignUpError) return <ErrorPage500 />
  if (configLoading) return <Loading />
  if (!config?.isRewardsEnabled) return <InactiveProgram />
  if (isSignUpSuccess) return <Successful />

  const {
    signUpMethod,
    programName,
    programDescription,
    smsMarketingAccountEnabled
  } = config

  const contactMethod =
    signUpMethod === SignupMethod.EMAIL ? 'email' : 'phone number'

  return (
    <Layout>
      <div className='type-large text-default text-center font-bold'>
        Sign up for {programName}
      </div>
      <div className='type-default text-secondary text-center'>
        {config?.restaurantName}
      </div>

      <div className='type-default text-default text-center'>
        {programDescription}
      </div>
      <SignupForm />
      {smsMarketingAccountEnabled &&
        signUpMethod === SignupMethod.PHONE_NUMBER && (
          <div
            className='type-subhead sm:w-2/3 w-full text-center'
            aria-label='terms-and-conditions'
          >
            By enrolling, you agree to participate in the rewards program and
            receive automated, personalized, informational and marketing text
            messages (if consented) at this number from this restaurant and
            restaurant group. Consent not a condition of purchase. Message and
            data rates may apply, frequency varies. Reply STOP to opt out.
            Subject to{' '}
            <a
              className='text-link'
              href='https://pos.toasttab.com/terms-of-service'
            >
              Toast's Terms
            </a>{' '}
            and{' '}
            <a className='text-link' href='https://pos.toasttab.com/privacy'>
              Privacy Statement.
            </a>
          </div>
        )}
      {(!smsMarketingAccountEnabled || signUpMethod === SignupMethod.EMAIL) && (
        <div
          className='type-subhead sm:w-2/3 w-full text-center'
          aria-label='terms-and-conditions'
        >
          By providing your {contactMethod}, you are agreeing to participate in
          the rewards program and to be contacted through this {contactMethod}{' '}
          as part of the program. Rewards program information is subject to{' '}
          <a
            className='text-link'
            href='https://pos.toasttab.com/terms-of-service'
          >
            Toast's Terms of Service
          </a>{' '}
          and{' '}
          <a className='text-link' href='https://pos.toasttab.com/privacy'>
            Privacy Statement.
          </a>
        </div>
      )}
    </Layout>
  )
}
