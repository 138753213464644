import React from 'react'
import { TextInput } from '@toasttab/buffet-pui-text-input'

type Params = {
  emailInput: string
  setEmailInput: (email: string) => void
  disabled?: boolean
}

export const EmailInput = ({
  emailInput,
  setEmailInput,
  disabled = false
}: Params) => {
  const onChangeEmail = (e: { target: { value: string } }) => {
    setEmailInput(e?.target?.value.trim() ?? '')
  }

  return (
    <TextInput
      label='Email'
      name='email'
      className='w-full'
      value={emailInput}
      onChange={onChangeEmail}
      disabled={disabled}
    />
  )
}
